






























































































import { SfImage, SfSection, } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'InstagramFeed',
  components: {
    SfSection,
    SfImage,
  },
});
